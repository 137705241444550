import React from "react"
import * as s from "./Post.module.scss"
import Layout from "../../Layout"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import Sidebar from "./_Sidebar"
import { format } from "date-fns"
import get from "lodash/get"

const BlogPost = ({
  pageContext: {
    post: {
      data: {
        post_date,
        post_title: { text: title },
        body: { html: body },
        image,
      },
    },
  },
}) => {
  const imageUrl = get(image, "url", null)
  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs titleAlt={"Blog"} />

      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <h1 className={s.post_title}>{title}</h1>
            <time>{post_date}</time>
            <hr />
            {imageUrl && (
              <img src={imageUrl} alt={title} className={s.main_image} />
            )}
            <div
              dangerouslySetInnerHTML={{ __html: body }}
              className={s.body}
            />
          </div>
          <div className="col-lg-4 d-none d-lg-block">
            <Sidebar />
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default BlogPost
