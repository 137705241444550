import React, { useContext } from "react"
import { Link } from "gatsby"
import { StoreContext } from "../../contexts/Store"
import * as s from "./Sidebar.module.scss"
import { HiChevronDoubleRight } from "react-icons/hi"

const Sidebar = () => {
  const {
    store: { categories },
  } = useContext(StoreContext)
  return (
    <div className={"sticky-top"}>
      <Link to={"/organic-store"} className={"w-100 btn btn-primary"}>
        Visit our Organic Store
      </Link>

      <Categories categories={categories} />
    </div>
  )
}
export default Sidebar

const Categories = ({ categories = [] }) => {
  if (categories.length === 0) return null
  return (
    <>
      <h4 className={"mt-4"}>Our Product Categories</h4>
      <ul className={s.categories}>
        {categories.map(({ slug, name }) => {
          return (
            <li key={slug}>
              <Link to={`/organic-store/${slug}`}>
                <HiChevronDoubleRight /> <span>{name}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}
